import React, {useContext} from "react"
import Helmet from "react-helmet"

import Layout from '../components/layout'
import Header from '../components/Header'
import Section, { TextSection, BackgroundSection } from '../components/Section'


import pic01 from '../assets/images/pic01.jpg'
import pic02 from '../assets/images/pic02.jpg'
import pic03 from '../assets/images/pic03.jpg'
import pic04 from '../assets/images/pic04.jpg'

import { getMediaHeader, getStaticPageData } from "../lib/API"
import { useData, useLocalData } from "../lib/hooks"
import Link from "../components/Link"
import BannerSection from "../components/BannerSection"
import VideoHighlight from "../components/VideoHighlight"

// import LiveSermonImage from '../assets/images/home/live-camera.jpg'
// import LiveSermonImage from '../assets/images/general/8.jpg'
import LiveSermonImage from "../assets/images/church-interior/10.jpg"


// import AudioImage from '../assets/images/home/audio.jpg'
import AudioImage from '../assets/images/core/Front5_Cropped.jpg'
import BannerContext from "../lib/context"



const MediaPage = () => {
    const siteTitle = "Blackheath and Charlton Baptist Church"

    const pageName = 'media-banners'
    // const pageData = useLocalData( getStaticPageData, pageName, pageName )
    const pageData = useContext( BannerContext )[pageName]
    const { header, banners } = pageData || { header: null, banners: null }
    const headerImages = header ? header.images : [] 
    const headerTitle = header ? header.title : 'Services & Media'
    
    // const headerImages = useData( getMediaHeader )
    // const headerTitle = "Services and Media"
    const headerIcon = <span className="icon major"><i className="fas fa-film"></i></span>
    const headerButtons = [
        { url: "/media#one", text: "View Sermons" }
    ]

    return (
            
        <div id='media-page'>
            <Helmet title={`${siteTitle} - Services and Media`} />
            <Header images={headerImages} headerTitle={headerTitle} tint={0.1} headerButtons={headerButtons} />

            <section id="services" className="main style1 special">
                <div className="grid-wrapper">
                    
                    <div className="col-12">
                        <header className="major">
                            <h2>Sunday Morning Service</h2>
                            <h3>10:30am</h3>
                        </header>
                        <p>You are welcome to join us in person or alternatively join us online for our livestreaming service.</p>
                        
                    </div>
                    
                    {/* <div className="col-6">
                        <header className="major">
                            <h2>Sunday Evening Service</h2>
                            <h3>6:00pm</h3>
                        </header>
                        <p>Our Sunday Evening Services are an informal forum for prayer, reflections and also provide an opportunity to ask questions about current theological issues.</p>
                    </div> */}
                   
                </div>
            </section>

            <BackgroundSection 
                id="live-sermons" 
                title="Live Service"
                style="style1"
                background={[ LiveSermonImage ]}
                description="Access our livestreamed services here"
                buttons={[ { url: 'https://www.youtube.com/channel/UCo1-ajfKW62orxAK4TPkQ3Q/live', external: true, text: 'Watch Now', style: 'transparent-button' } ]}
                tint={0.0}
                blur={0.1}

            />

            <VideoHighlight />   

            {/* <BackgroundSection 
                id="audio-section" 
                title="Audio Sermons"
                className='style2'
                description={`Find all our audio sermons here, where you can listen and be enriched by the word of God`}
                background={[ AudioImage ]}
                buttons={[ { url: '/media/audio', special: false, text: 'Listen',  style: 'transparent-button' } ]}
                tint={0.55}
                blur={0.05}
                alt

            /> */}

            <BackgroundSection 
                id="audio-section" 
                title="Prayer Meeting Audio"
                className='style2'
                description={`Find all our prayer meeting audios here, where you can listen and be enriched by the word of God`}
                background={[ AudioImage ]}
                buttons={[ { url: '/media/audio', special: false, text: 'Listen',  style: 'transparent-button' } ]}
                tint={0.55}
                blur={0.05}
                alt

            />

            <BannerSection banners={banners} />
            
        </div>
    )
}

export default MediaPage